import { useTranslation } from 'react-i18next'

export function Team() {
  const { t } = useTranslation(['team', 'common'])

  const members = [
    {
      name: 'Willem Guensberg',
      function: t('functions.willem'),
      image: require('../assets/team/willem.webp'),
    },
    {
      name: 'Peter Slump',
      function: t('functions.developer'),
      image: require('../assets/team/peter.webp'),
    },
    {
      name: 'Timo de Winter',
      function: t('functions.timo'),
      image: require('../assets/team/timo.webp'),
    },
    {
      name: 'Sil Kreulen',
      function: t('functions.developer'),
      image: require('../assets/team/sil.webp'),
    },
    {
      name: 'Sander de Leeuw',
      function: t('functions.developer'),
      image: require('../assets/team/sander.webp'),
    },
  ]

  return (
    <section className="mt-[5.25rem] lg:mt-[10rem]">
      <div className="outer-container">
        <h2 className="h2">{t('title')}</h2>
        <p className="subtitle">{t('subtitle')}</p>

        <div className="mt-8 md:mt-20 grid grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-6 sm:gap-y-12 sm:gap-x-16 md:gap-y-12 md:gap-x-12 lg:gap-y-16 lg:gap-x-32">
          {members.map((member, index) => (
            <div key={index}>
              <div className="bg-neutral-200 aspect-[144/155] rounded-lg mb-4 md:mb-4 lg:mb-8 overflow-hidden flex items-end">
                <img src={member.image} alt={member.name} />
              </div>
              <h3 className="h3">{member.name}</h3>
              <p className="font-display font-bold text-orange-400 mt-0.5 md:mt-1 text-sm md:text-base">
                {member.function}
              </p>
            </div>
          ))}
          <a href={t('common:jobsUrl')} className="group">
            <div className="rounded-lg mb-4 md:mb-4 lg:mb-8 w-full bg-orange-200 flex items-center justify-center aspect-[144/155]">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 97 120"
                className="w-[4rem] sm:w-[6rem] group-hover:scale-110 text-orange-400 group-hover:text-orange-500 transition"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M61.598 56.941c0 7.225-4.653 12.08-11.577 12.08h-1.325c-3.045 0-5.459-.06-9.867-.823v-23.32c3.901-.557 7.117-.788 10.805-.788 7.603 0 11.964 4.684 11.964 12.851Zm28.015 36.074c-5.907 10.835-39.526 26.046-40.955 26.689l-.66.296-.655-.31c-1.393-.66-34.197-16.285-40.864-26.573-9.278-14.321-7.956-51.641-.062-66.13C12.324 16.151 45.943.94 47.372.296l.66-.297.654.31c1.394.66 34.198 16.286 40.866 26.574 9.278 14.322 7.955 51.642.061 66.13Zm-19.706-36.25c-.17-12.356-7.885-20.34-19.654-20.34-6.194 0-12.292.64-18.641 1.959l-1.247.258v46.03l8.387 4.566v-13.34c4.904.788 7.74.788 11.81.788 11.39 0 19.345-8.182 19.345-19.92Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <h3 className="h3">{t('you.name')}</h3>
            <p className="font-display font-bold text-orange-400 mt-0.5 md:mt-1 text-sm md:text-base">
              {t('you.function')}
            </p>
          </a>
        </div>
      </div>
    </section>
  )
}
