import { CallToAction } from './sections/CallToAction'
import { Cases } from './sections/Cases'
import { FAQ } from './sections/FAQ'
import { Footer } from './sections/Footer'
import { Header } from './sections/Header'
import { Hero } from './sections/Hero'
import { Team } from './sections/Team'
import { Video } from './sections/Video'

import './i18n/config'

function App() {
  return (
    <div
      className={`
        bg-[url('assets/hero/hero-background-mobile.webp'),_url('assets/footer/footer-background-mobile.webp')]
        bg-[length:375px_528px,375px_257px]
        md:bg-[url('assets/hero/hero-background.webp'),_url('assets/footer/footer-background-tablet.webp')]
        md:bg-[length:2556px_980px,1024px_482px]
        lg:bg-[url('assets/hero/hero-background.webp'),_url('assets/footer/footer-background.webp')]
        lg:bg-[length:2556px_980px,2559px_650px]
        bg-no-repeat
        bg-[position:center_top,center_bottom]

        md:bg-[position:center_top,center_bottom]
      `}
    >
      <Header />
      <Hero />
      <Cases />
      <Video />
      <Team />
      <FAQ />
      <CallToAction />
      <Footer />
    </div>
  )
}

export default App
