import LanguageDetector from 'i18next-browser-languagedetector'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const resources = {
  en: {
    common: require('./en/common.json'),
    header: require('./en/header.json'),
    hero: require('./en/hero.json'),
    cases: require('./en/cases.json'),
    video: require('./en/video.json'),
    team: require('./en/team.json'),
    faq: require('./en/faq.json'),
    callToAction: require('./en/callToAction.json'),
    footer: require('./en/footer.json'),
  },
  nl: {
    common: require('./nl/common.json'),
    header: require('./nl/header.json'),
    hero: require('./nl/hero.json'),
    cases: require('./nl/cases.json'),
    video: require('./nl/video.json'),
    team: require('./nl/team.json'),
    faq: require('./nl/faq.json'),
    callToAction: require('./nl/callToAction.json'),
    footer: require('./nl/footer.json'),
  },
} as const

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: [
      'header',
      'hero',
      'cases',
      'video',
      'team',
      'faq',
      'callToAction',
      'footer',
    ],
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  })

export default i18n
